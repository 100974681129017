import React from 'react'
import ranjana from '../images/ranjana_plain.svg'

const IntroPage = () => {
  return (
    <div className="introduction">
      <h2 className="major">Introduction</h2>
      <div className="image main">
        <img src={ranjana} alt="IntroRanjana" />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            fontSize: '0.75rem',
          }}
        >
          <small>- Ranjana Lipi</small>
        </div>
      </div>
      <p>
        I am a DevOps and Software Engineer with more than 6 years of software
        engineering experience. I reside in Lalitpur, Nepal and hold Bachelor's
        Degree in Computer Engineer from Kantipur Engineer College, affiliated
        to Tribhuvan University. I also had been a recipient of Best Student in
        Mathematics award.
      </p>
      <p>
        I have hands-on experience on DevOps processes, CI/CD, Containerization
        and Orchestration, leveraging configuration management, supporting,
        automating and optimizing mission critical deployments in AWS, building
        scalable and optimized web applications.
      </p>
      <p>
        I use mainly Kubernetes, Gitlab CI, Github Actions, Terraform, Ansible,
        Grafana (Prometheus, Loki, Tempo) to orchestrate, deliver, provision,
        configure and monitor systems, and Python (Django/Flask/FastAPI) and
        Golang with Postgres database to translate business requirements
        into reliable, scalable and resilient software systems.
      </p>
      <p>
        Besides software engineering, residing in Lalitpur, the city of Arts and Crafts, I also have keen interest in art and love to carry on the hereditary work of carving and sculpturing copper and silver statues.
      </p>
    </div>
  )
}
export default IntroPage

import PropTypes from 'prop-types'
import React from 'react'
import face from '../images/face.jpg'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <img className="logo-image" src={face} alt="" />
    </div>
    <div className="content">
      <div className="inner">
        <h1>Er. Sumedh Shakya</h1>
        <p>A DevOps and Software (Python/Golang) Engineer</p>
        <p>A Handicraft Artist</p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
            style={{ width: '100%' }}
          >
            Introduction
          </button>
        </li>
        {/* <li>
          <button
            onClick={() => {
              props.onOpenArticle('education')
            }}
            style={{ width: '100%'}}
          >
            Education
          </button>
        </li> */}
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('experience')
            }}
            style={{ width: '100%' }}
          >
            Experience
          </button>
        </li>
        {/* <li>
          <button
            onClick={() => {
              props.onOpenArticle('art-culture')
            }}
            style={{ width: '100%'}}
          >
            Projects
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('art-culture')
            }}
            style={{ width: '100%'}}
          >
            Articles
          </button>
        </li> */}
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
            style={{ width: '100%' }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header

import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ContactPage = () => {
  const [state, setState] = React.useState({
    name: '',
    email: '',
    message: '',
  })

  const handleChange = evt => {
    const value = evt.target.value
    setState({
      ...state,
      [evt.target.name]: value,
    })
  }

  const resetHandler = event => {
    setState({
      name: '',
      email: '',
      message: '',
    })
  }

  const submitHandler = event => {
    toast('You will be contacted soon!!!')
    axios.post(`${process.env.GATSBY_API_URL}/contact`, state).then(res => {
      setState({
        name: '',
        email: '',
        message: '',
      })
    })
    event.preventDefault()
  }

  return (
    <div>
      <h2 className="major">Contact</h2>
      <h6> Contact Me for collaboration and any queries </h6>
      <form onSubmit={submitHandler}>
        <div className="field half first">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={state.name}
            onChange={handleChange}
            name="name"
          />
        </div>
        <div className="field half">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            value={state.email}
            onChange={handleChange}
            id="email"
            name="email"
          />
        </div>
        <div className="field">
          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            id="message"
            rows="4"
            value={state.message}
            onChange={handleChange}
          />
        </div>
        <ul className="actions">
          <li>
            <input type="submit" value="Send Message" className="special" />
          </li>
          <li>
            <input type="reset" onClick={resetHandler} />
          </li>
        </ul>
      </form>
      <ul className="icons">
        <li>
          <a
            href="https://x.com/Sakya_Sumedh"
            className="icon fa-twitter"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/sumedh.shakya.963"
            className="icon fa-facebook"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://linkedin.com/in/sumedh-shakya"
            className="icon fa-linkedin"
          >
            <span className="label">LinkedIn</span>
          </a>
        </li>
        <li>
          <a href="https://github.com/SakyaSumedh" className="icon fa-github">
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a
            href="https://medium.com/@sakyasumedh"
            className="icon fab fa-medium"
          >
            <span className="label">Medium</span>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default ContactPage
